import React from "react";
import BackIcon from '../../components/svg/commons/arrow_head'
import PhoneIcon from '../../components/svg/commons/phone_ringing'
import SvgMap from '../../components/svg/dealers/maps';
import SvgSite from '../../components/svg/dealers/site';

const BranchOfficesList = ({branchoffices=[], handleBranchoffice, handleBackMain, handleOver,isComercial=false}) => {
    function handleClick (e, branchoffice) {
        const elActive = document.querySelector(".c-dealers-branchoffices__card.isActive");
        if(elActive) {
            elActive.classList.remove("isActive");
        }
        e.target.parentElement.classList.add("isActive");
        //console.log('handleBranchoffice list',branchoffice)
        handleBranchoffice(branchoffice)
    }

    function Card(branchoffice) {
        
        return(
            <div className="c-dealers-branchoffices__card" key={branchoffice.uid} onMouseEnter={() =>{handleOver(branchoffice)}} onMouseLeave={() =>{handleOver(null)}}>
                <h3 onClick={e => handleClick(e, branchoffice)}>{branchoffice.data.branchoffice}</h3>
                <p onClick={e => handleClick(e, branchoffice)}>{branchoffice.data.address}</p>
                <p>{branchoffice.data.phone}</p>
                <div className="card__links">
                    <Link text="cómo llegar" url={`http://maps.google.com/maps?&z=15&mrt=yp&t=k&q=${branchoffice.data.coordinates.latitude},${branchoffice.data.coordinates.longitude}`}>
                        <SvgMap />
                    </Link>
                    <Link text="Ver sitio" url={branchoffice.data.website}>
                        <SvgSite />
                    </Link>
                    <Link text="Llamar" url={`tel:${branchoffice.data.phone}`}>
                        <PhoneIcon />
                    </Link>
                </div>
            </div>
        )
    }

    function Link (props) {
        const { text, url, children } = props;
        return (
            <a href={url}>
                {children}
                <span>{text}</span>
            </a>
        )
    }

    return (
        <div className="c-dealers-branchoffices">

            {
                branchoffices.length > 0  ? 
                    <div className="c-dealers-branchoffices__title">
                        {
                        !isComercial ?
                            <>
                                <div className="c-dealers-branchoffices__back" onClick={() => handleBackMain()}>
                                    <BackIcon />
                                    <span>Regresar</span>
                                </div>
                                <h2>{branchoffices[0].data.store}</h2>
                            </>
                            :
                            <h2 className="c-dealers-branchoffices__title--comercial">Distribuidores Comerciales</h2>
                        }
                        
                    </div>
                    : null
            }
            {
                branchoffices.map(branchoffice => Card(branchoffice))
            }
        </div>
    )
}

export default BranchOfficesList;