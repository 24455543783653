import React, { useEffect, useRef } from 'react';
import { GoogleMap, withScriptjs, withGoogleMap, Marker } from 'react-google-maps';
import mapStyle from '../../../static/assets/data/dealers/styleMap.json'; 
import pin from '../../../static/assets/images/dealers/pin.png';
import pinOver from '../../../static/assets/images/dealers/pin_over.png';

function Map({location, ref}) {
    //const refSearch = useRef(null)
    

    // useEffect(()=>{
    //     console.log('defaultLocation',defaultLocation)
    // },[defaultLocation])
    return(
        <GoogleMap
            ref={ref}
            defaultZoom={10}
            defaultCenter={{lat: location.data.coordinates.latitude, lng: location.data.coordinates.longitude}}
            defaultOptions={{styles: mapStyle}}
            zoom={location ? 17 : 10}
        >
            
            <Marker icon={pinOver} key={2} position={{lat: location.data.coordinates.latitude, lng: location.data.coordinates.longitude}} />
        </GoogleMap>
    )
}


function setIconMouseOver(_mark){
    // console.log(_mark);
}


function MapLg({location, locations, defaultLocation, ref, overStore, isBack, setIsBack, overBranchoffice}) {

    const refMap = useRef(null)
    //const refSearch = useRef(null)
    function fitStateBounds(locations){        
        
        if(locations.length!==0) {
            let bounds;
            bounds = new window.google.maps.LatLngBounds();
            locations.map((_location)=>{
                if(_location!==null) {
                   return bounds.extend(new window.google.maps.LatLng(
                        _location.data.coordinates.latitude,
                        _location.data.coordinates.longitude
                ))  
                } else return null
                
            })
            // handleBound(bounds)
            
            // const boundsDelGet = refMap.getBounds()
            refMap.current.fitBounds(bounds)
            // refMap.current.center = bounds.getCenter();
        }       
        
    }
    
    useEffect(()=>{
        //console.log('cambia locations',locations)
        fitStateBounds(locations)
    },[locations])

    /* useEffect(()=>{
        //console.log('cambia location-->',location)
    },[location]) */
    /* useEffect(()=>{
        //console.log('cambia defaultLocation',defaultLocation)
       
    },[defaultLocation]) */
    

    function handleOverStore(locationItem) {
        if(overStore.toLowerCase() === locationItem.data.store.toLowerCase() ) {
            return pinOver
        }
        return pin
    }

    function handleOverBranchOffice(locationItem) {
        if(overBranchoffice.uid === locationItem.uid) {
            return pinOver
        }
        return pin
    }

    useEffect(() => {
        if(!isBack) return

        fitStateBounds(locations)
        setIsBack(false)
    }, [isBack, locations, setIsBack])


    return(
        <GoogleMap
            ref={refMap}
            defaultZoom={5}
            defaultCenter={{
                lat: defaultLocation.latitude, 
                lng: defaultLocation.lngitude
            }}
            center= {{                
                lat: (locations.length===0) ? defaultLocation.latitude : (location!==null) ? location.data.coordinates.latitude : null, 
                lng: (locations.length===0) ? defaultLocation.lngitude : (location!==null) ? location.data.coordinates.longitude : null
            }} 
            
            
            zoom={location ? 15 : defaultLocation.zoom}
            defaultOptions={{styles: mapStyle}}
        >
            {
                location ?
                    <Marker
                        icon={pinOver}
                        onMouseOver={setIconMouseOver}
                        position={{lat: location.data.coordinates.latitude, lng: location.data.coordinates.longitude}}
                    />
                :
                    locations.map((locationItem, i) => {
                        return <Marker
                                    key={i + "-location"}
                                    icon={overStore ? handleOverStore(locationItem) :  overBranchoffice ? handleOverBranchOffice(locationItem) : pin }
                                    onMouseOver={setIconMouseOver}
                                    position={{lat: locationItem.data.coordinates.latitude, lng: locationItem.data.coordinates.longitude}}
                                />
                    })
            }
            
        </GoogleMap>
    )
}

export const WrappedMap = withScriptjs(withGoogleMap(Map));
export const WrappedMapLg = withScriptjs(withGoogleMap(MapLg));
