import React, { useState, useRef, useEffect } from 'react';

import useOutsideClick from '../../hooks/useOutsideClick'
import IconArrow from '../svg/commons/arrow_head';
import "../../styles/_components/_comparator/_dropdown.scss";

const Dropdown = ({items, default_item = "name item", handleItemClick, _className="", type=1, disabled=false}) => {
    const [isActive, setActive] = useState(false);
    const wrapperRef = useRef(null);
    useOutsideClick(wrapperRef, setActive);

    //useEffect for add and remove class for z-index=1
    useEffect(()=>{
        if(isActive){
            wrapperRef.current.classList.add("o-dropdown__active");
        }else{
            wrapperRef.current.classList.remove("o-dropdown__active");
        }
    },[isActive])

    useEffect(()=>{
        if(disabled) {
            wrapperRef.current.classList.add('disabled')
            return
        }
        wrapperRef.current.classList.remove('disabled')

    },[disabled])

    function handleClick(item) {
        
        if(type === 1) {
            handleItemClick(item.uid);
        } else {
            handleItemClick(item);
        }
        setActive(false);
    }

    function getItems() {
        return (
            <div className="o-dropdown__list">
                <div className="o-dropdown__list--wrapper" onClick={() => setActive(!isActive)}>
                    <ul>
                    {items.map( item => <li key={item.uid} onClick={() => handleClick(item)}><div>{item.data.title.text}</div></li>)}
                    </ul>
                </div>    
            </div>
        )
    }

    return (
        <div className="o-dropdown" ref={wrapperRef}>
            <div className={`o-dropdown__button ${_className}`} onClick={() => setActive(!isActive)} >
                <p>{default_item}</p>
                <IconArrow color_start='#f84f5f' fill='#f84f5f' color_end='#f84f5f' section='_models'/>
            </div>
            {
                isActive && getItems()
            }
        </div>
    );
};

export default Dropdown;