import React from "react"

const DealersFind = props => (
  
    <svg height={300} width={300} viewBox="15 -15 100 100" {...props}>
      <path
        d="M103.3 73.3c-1.6 1.6-4.1 1.6-5.6 0L77.8 53.5c-5.6 4.4-12.7 7-20.3 7-18.2 0-33-14.8-33-33s14.8-33 33-33 33 14.8 33 33c0 7.7-2.6 14.7-7 20.3l19.8 19.9c1.6 1.6 1.6 4.1 0 5.6zM57.5 2.5c-13.8 0-25 11.2-25 25s11.2 25 25 25 25-11.2 25-25-11.2-25-25-25z"
        fillRule="evenodd"
        clipRule="evenodd"
      />
    </svg>
  )

export default DealersFind
