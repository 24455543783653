import React from 'react'
import styled from 'styled-components'

import Loading from '../loading/Loading'

export const LoaderCotainer = styled.div`
    font-family: 'proxima-nova';
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background: rgba(0, 0, 0, 0.1); */
    width: 100%;
    height: 100%;
    /* padding-top: 100px; */
    z-index: 50;
    position: relative;
    span {
      text-transform: uppercase;
      font-size: 14px;
      letter-spacing: 1px;
    }
    @media screen and (min-width: 768px){
      width: 330px;
    }
    @media screen and (min-width:1024px){
      width: 465px;
    }
`

const Loader = ({ customClass }) => {
  return (
    <LoaderCotainer className={customClass}>
      <Loading />
      <span>Cargando</span>
    </LoaderCotainer>
  )
}

export default Loader
