import React from "react"

import Layout from "../components/layout"
import Dealers from "../content/dealers/dealers"
import "../styles/pages/dealers/_dealers.scss"

const PageDealers = () => {
  return (
    <Layout chat={false}>
      <Dealers />
    </Layout>
  )
}

export default PageDealers
