import Prismic from 'prismic-javascript'
const apiEndpoint = "https://calorexw.cdn.prismic.io/api/v2";
const apiToken = "MC5YWVVtS2hFQUFDY0FkekFO.WG7vv70jegXvv71F77-977-9Ze-_ve-_ve-_ve-_ve-_vQnvv73vv707Ue-_ve-_ve-_vXtufe-_vX7vv73vv71x";

export const fetchGeopoint = async (lat, lng) => {
    try {
        const api = await Prismic.getApi(apiEndpoint, { accessToken: apiToken });
        const response = await api.query(Prismic.Predicates.near('my.location.coordinates', lat, lng, 15), {pageSize: 5});
        return response;
    }
    catch (err) {
        console.log("Something went wrong: ", err);
    }
}
