export const states = [
    { "uid": "AGS", id: "1", data:{ title: {"text": "AGUASCALIENTES", "shortName": "Ags."}}},
    { "uid": "BC", id: "2", data:{ title: {"text": "BAJA CALIFORNIA", "shortName": "B.C."}}},
    { "uid": "BCS", id: "3", data:{ title: {"text": "BAJA CALIFORNIA SUR", "shortName": "B.C.S."}}},
    { "uid": "CHI", id: "8", data:{ title: {"text": "CHIHUAHUA", "shortName": "Chih."}}},
    { "uid": "CHS", id: "7", data:{ title: {"text": "CHIAPAS", "shortName": "Chis."}}},
    { "uid": "CMP", id: "4", data:{ title: {"text": "CAMPECHE", "shortName": "Camp."}}},
    { "uid": "CMX", id: "9", data:{ title: {"text": "CIUDAD DE MÉXICO", "shortName": "CDMX"}}},
    { "uid": "COA", id: "5", data:{ title: {"text": "COAHUILA", "shortName": "Coah."}}},
    { "uid": "COL", id: "6", data:{ title: {"text": "COLIMA", "shortName": "Col."}}},
    { "uid": "DGO", id: "10", data:{ title: {"text": "DURANGO", "shortName": "Dgo."}}},
    { "uid": "GRO", id: "12", data:{ title: {"text": "GUERRERO", "shortName": "Gro."}}},
    { "uid": "GTO", id: "11", data:{ title: {"text": "GUANAJUATO", "shortName": "Gto."}}},
    { "uid": "HGO", id: "13", data:{ title: {"text": "HIDALGO", "shortName": "Hgo."}}},
    { "uid": "JAL", id: "14", data:{ title: {"text": "JALISCO", "shortName": "Jal."}}},
    { "uid": "MCH", id: "16", data:{ title: {"text": "MICHOACÁN", "shortName": "Mich."}}},
    { "uid": "MEX", id: "15", data:{ title: {"text": "ESTADO DE MÉXICO", "shortName": "Méx."}}},
    { "uid": "MOR", id: "17", data:{ title: {"text": "MORELOS", "shortName": "Mor."}}},
    { "uid": "NAY", id: "18", data:{ title: {"text": "NAYARIT", "shortName": "Nay."}}},
    { "uid": "NL",  id: "19", data:{ title: {"text": "NUEVO LEÓN", "shortName": "N.L."}}},
    { "uid": "OAX", id: "20", data:{ title: {"text": "OAXACA", "shortName": "Oax."}}},
    { "uid": "PUE", id: "21", data:{ title: {"text": "PUEBLA", "shortName": "Pue."}}},
    { "uid": "QR",  id: "23", data:{ title: {"text": "QUINTANA ROO", "shortName": "Q.R."}}},
    { "uid": "QRO", id: "22", data:{ title: {"text": "QUERÉTARO", "shortName": "Qro."}}},
    { "uid": "SIN", id: "25", data:{ title: {"text": "SINALOA", "shortName": "Sin."}}},
    { "uid": "SLP", id: "24", data:{ title: {"text": "SAN LUIS POTOSÍ", "shortName": "S.L.P."}}},
    { "uid": "SON", id: "26", data:{ title: {"text": "SONORA", "shortName": "Son."}}},
    { "uid": "TAB", id: "27", data:{ title: {"text": "TABASCO", "shortName": "Tab."}}},
    { "uid": "TLX", id: "29", data:{ title: {"text": "TLAXCALA", "shortName": "Tlax."}}},
    { "uid": "TMS", id: "28", data:{ title: {"text": "TAMAULIPAS", "shortName": "Tamps."}}},
    { "uid": "VER", id: "30", data:{ title: {"text": "VERACRUZ", "shortName": "Ver."}}},
    { "uid": "YUC", id: "31", data:{ title: {"text": "YUCATÁN", "shortName": "Yuc."}}},
    { "uid": "ZAC", id: "32", data:{ title: {"text": "ZACATECAS", "shortName": "Zac."}}} 
]